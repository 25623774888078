export const formatNumber = (num) => {
	if (num) {
		if (num >= 1000 && num < 1000000) {
			return (num / 1000).toFixed(2) + "k";
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(2) + "m";
		} else {
			return num.toString();
		}
	} else {
		return 0;
	}
};
