import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: 0,
  kycData: null,
};

const kycSlice = createSlice({
  name: "kycStatus",
  initialState,
  reducers: {
    setKyc: (state, action) => {
      state.status = action.payload.status;
      state.kycData = action.payload.data;
    },
  },
});

export const { setKyc } = kycSlice.actions;
export const selectKyc = (state) => state.kycStatus;
export default kycSlice.reducer;
