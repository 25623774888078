import axios from "axios";

const customApi = axios.create({
	// baseURL: process.env.REACT_APP_API_URL,
	baseURL: process.env.REACT_APP_API_URL_LIVE,
	headers: {
		email: process.env.REACT_APP_API_EMAIL,
		password: process.env.REACT_APP_API_PASS,
	},
});
export const fetchMyProfile = async (id) => {
	try {
		const { data } = await customApi.get(`/view/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getAllSettings = async () => {
	try {
		const { data } = await customApi.get(`/api/user/settings/site/details`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const completeKYC = async (values) => {
	try {
		const { data } = await customApi.post(`/update-kyc`, values);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const updateUsername = async (values) => {
	try {
		const { data } = await customApi.post(`/update-username`, values);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const updateAvatar = async (values) => {
	try {
		const { data } = await customApi.post(`/update-avatar`, values);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getKYCStatus = async (values) => {
	try {
		const { data } = await customApi.post(`/kyc/status`, values);

		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const updateBalance = async (values) => {
	try {
		const { data } = await customApi.post(`/api/wallet/balance`, values);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const createOrder = async (order) => {
	try {
		const { data } = await customApi.post(`/api/wallet/payment/order`, order);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const createOrderUpiPay = async (order) => {
	try {
		const { data } = await customApi.post(
			"/api/wallet/upi-payment/order",
			order
		);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getPaymentStatus = async (ids) => {
	try {
		const { data } = await customApi.post(
			"/api/wallet/check_order_status",
			ids
		);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const addMoneyToWallet = async (walletData) => {
	try {
		const { data } = await customApi.post(`/api/wallet/create`, walletData);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const walletHistory = async (values) => {
	try {
		const { data } = await customApi.post(`/api/wallet/history`, values);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getMyWithdrawReq = async (id) => {
	try {
		const { data } = await customApi.get(`/user-with-draw-request/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const cancelMyWithdrawReq = async (id) => {
	try {
		const { data } = await customApi.get(`/cancel-with-draw-request/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const getAllNotifications = async (id) => {
	try {
		const { data } = await customApi.get(`/api/user/notify/all/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const getAllGames = async () => {
	try {
		const { data } = await customApi.get(`/api/game/all`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getGameHistory = async (id) => {
	try {
		const { data } = await customApi.get(`/api/game/history/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getRoomCode = async (rData) => {
	try {
		const { data } = await customApi.post(`/api/user/room/create`, rData);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getRoomsById = async (id, uid) => {
	try {
		const { data } = await customApi.get(`/api/user/room/all/${id}/${uid}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const roomCodeHistory = async (code, userId) => {
	try {
		const { data } = await customApi.get(
			`/challenger-history-details/${code}/${userId}`
		);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getRunningRooms = async () => {
	try {
		const { data } = await customApi.get(`/api/running-challengers`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const deleteRoomReq = async (id) => {
	try {
		const { data } = await customApi.delete(`/game-request-delete/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const updateNotification = async (nData) => {
	try {
		const { data } = await customApi.post(`/api/user/notify/update`, nData);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const markAllRead = async (id) => {
	try {
		const { data } = await customApi.get(`/api/user/notify/read/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const getPaykey = async () => {
	try {
		const { data } = await customApi.get(`/api/user/payment/key`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getLoginSettings = async () => {
	try {
		const { data } = await customApi.get("/api/user/settings/site/details");
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const withdrawalsData = async (values) => {
	try {
		const { data } = await customApi.post(
			`/api/user/transaction/withdraw/request`,
			values
		);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};

export const addScreenshots = async (values) => {
	try {
		const { data } = await customApi.post(
			`/api/user/game/update/screenshot`,
			values
		);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getChatList = async (id) => {
	try {
		const { data } = await customApi.get(`/api/user/chats/list/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const sendMessage = async (values) => {
	try {
		const { data } = await customApi.post("/api/user/chats/save", values);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const createRoomCode = async (values) => {
	try {
		const { data } = await customApi.post(
			"/api/user/room/custom-create",
			values
		);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const gameRequest = async (rData) => {
	try {
		const { data } = await customApi.post(`/game-request`, rData);

		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const roomCodeAllData = async (id) => {
	try {
		const { data } = await customApi.get(`/room-code-data/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const cancelRoom = async (cData) => {
	try {
		const { data } = await customApi.post(`/canceled-game`, cData);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getAllStates = async () => {
	try {
		const { data } = await customApi.get("/api/states/all/101");
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const getAllCities = async (id) => {
	try {
		const { data } = await customApi.get(`/api/cities/all/${id}`);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
export const withdrawWallet = async (cData) => {
	try {
		const { data } = await customApi.post(`/refer-transfer`, cData);
		return data;
	} catch (error) {
		return {
			status: false,
			message: error.response.data.message || error.message,
		};
	}
};
