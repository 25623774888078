import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../features/auth/authSlice";
import { ImProfile } from "react-icons/im";
import {
	BsCashCoin,
	BsWalletFill,
	BsFillPersonPlusFill,
	BsFillPatchQuestionFill,
} from "react-icons/bs";
import { FaHistory } from "react-icons/fa";
import { RiFolderHistoryFill } from "react-icons/ri";
import { VscReferences } from "react-icons/vsc";
import { AiFillNotification } from "react-icons/ai";
import { FcMoneyTransfer } from "react-icons/fc";
import { TbUserCheck } from "react-icons/tb";
import { getLoginSettings, updateBalance } from "../Services/usersApi";
import { selectWallet, setBalance } from "../features/wallet/walletSlice";
import { formatNumber } from "../utils/formatNumber";

const Sidebar = () => {
	const dispatch = useDispatch();
	let { pathname } = useLocation();
	const { user } = useSelector(selectUser);
	const { balance } = useSelector(selectWallet);
	const [isOpen, setIsopen] = useState(false);
	const [navLinks, setNavLinks] = useState([
		{
			path: "/profile",
			icons: <ImProfile />,
			links: "My Profile",
		},
		{
			path: "/home",
			icons: <BsCashCoin />,
			links: "Win Cash",
		},
		{
			path: "/my-wallet",
			icons: <BsWalletFill />,
			links: "My Wallet",
		},
		{
			path: "/games-history",
			icons: <FaHistory />,
			links: "Games History",
		},
		{
			path: "/transaction-history",
			icons: <RiFolderHistoryFill />,
			links: "Transaction History",
		},
		{
			path: "/refer-earn",
			icons: <BsFillPersonPlusFill />,
			links: "Refer And Earn",
		},
		{
			path: "/refer-history",
			icons: <VscReferences />,
			links: "Refer History",
		},
		{
			path: "/notifications",
			icons: <AiFillNotification />,
			links: "Notification",
		},
		{
			path: "/support",
			icons: <BsFillPatchQuestionFill />,
			links: "Support",
		},
	]);

	const ToggleSidebar = () => {
		isOpen === true ? setIsopen(false) : setIsopen(true);
	};

	const handleUpdateBalance = async () => {
		const balanceData = {
			user_id: user.id,
		};
		const data = await updateBalance(balanceData);
		if (data.status) {
			dispatch(setBalance(data.data.balance));
		}
	};

	const [image, setImage] = useState({
		site_logo: null,
	});

	const getLogo = async () => {
		const res = await getLoginSettings();
		if (res.status) {
			setImage({
				site_logo: res.data.site_logo,
			});
			if (res.data.allowAgentSignup === "1") {
				const agentSignup = {
					path: "/agent-signup",
					icons: <TbUserCheck />,
					links: "Signup as Agent",
				};
				setNavLinks([...navLinks, agentSignup]);
			}
		}
	};

	useEffect(() => {
		getLogo();
	}, []);

	useEffect(() => {
		handleUpdateBalance();
	}, [pathname]);

	return (
		<>
			<nav
				className="navbar navbar-expand-lg navbar-light bg-white shadow-md p-0 "
				style={{
					position: "absolute",
					left: "0",
					right: "0",
					top: "0",
					zIndex: "1",
				}}>
				<div className="container-fluid ">
					<div className="form-inline d-flex align-items-center">
						<div
							className="btn text-white custom-color"
							onClick={ToggleSidebar}>
							<FaBars />
						</div>
						<Link to={"/home"} className="navbar-brand  ms-3 py-2">
							<img
								src={image?.site_logo}
								width={"50px"}
								style={{ maxWidth: "100%!important" }}
							/>
						</Link>
						<Link to={"/my-wallet"} className="text-decoration-none">
							<Button
								size="sm"
								variant="success"
								className="d-flex align-items-center cash-button custom-color">
								<FcMoneyTransfer className="me-1" />

								<p className="m-0 fw-bold">₹ {formatNumber(balance)}</p>
							</Button>
						</Link>
					</div>
					{/* <Button
						onClick={handleLogout}
						size={"sm"}
						variant="success"
						className="d-flex me-3 align-items-center custom-color">
						<FiLogOut className="me-2" /> Logout
					</Button> */}
				</div>
			</nav>
			<div className={`custom-color sidebar ${isOpen == true ? "active" : ""}`}>
				<div className="text-end p-3">
					<div className="btn text-dark bg-white " onClick={ToggleSidebar}>
						<MdClose />
					</div>
				</div>
				<div>
					<div className="sd-body">
						<nav className="side-nav">
							<ul className="nav-menu">
								{navLinks.map((ele) => (
									<Link
										key={ele.links}
										to={ele.path}
										onClick={ToggleSidebar}
										className="text-decoration-none text-light">
										<li className="nav-item1">
											<div className="d-flex align-items-center">
												<h4 className="m-0">{ele.icons}</h4>
												<span className="menu-text">{ele.links}</span>
											</div>
										</li>
									</Link>
								))}
							</ul>
						</nav>
					</div>
				</div>
				<div
					className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
					onClick={ToggleSidebar}></div>
			</div>
		</>
	);
};

export default Sidebar;
