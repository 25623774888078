import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	webSettings: null,
};

const settingsSlice = createSlice({
	name: "webSettings",
	initialState,
	reducers: {
		setSettings: (state, action) => {
			state.webSettings = action.payload;
		},
	},
});

export const { setSettings } = settingsSlice.actions;
export const selectWebSettings = (state) => state.webSettings;
export default settingsSlice.reducer;
