import React, { useCallback, useLayoutEffect } from "react";
import Sidebar from "./Sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PlayGame from "../pages/PlayGame";
import { useDispatch, useSelector } from "react-redux";
import { selectCode, setCode } from "../features/game/gameSlice";
import { selectUser } from "../features/auth/authSlice";

const MainArea = () => {
	const { pathname } = useLocation();
	const notify = (message) => {
		toast(message);
	};
	const navigate = useNavigate();
	const { user } = useSelector(selectUser);
	const { isAuthenticated } = useSelector(selectUser);
	const dispatch = useDispatch();
	const { room_code } = useSelector(selectCode);

	const fetchSavedGame = useCallback(() => {
		let localRoom = JSON.parse(localStorage.getItem("activeGame"));
		if (isAuthenticated && +localRoom?.user_id === +user.id) {
			if (pathname === "/play-game") {
				notify("Please upload last game status");
			}
			return navigate("/play-game", {
				state: {
					room_code: localRoom.roomCode,
					game_id: localRoom.game_id,
					id: localRoom.id,
					user_id: localRoom.user_id,
				},
			});
		}
		dispatch(setCode(""));
	}, [user.id, dispatch, isAuthenticated, pathname, navigate]);

	useLayoutEffect(() => {
		fetchSavedGame();
	}, [pathname, fetchSavedGame]);

	return (
		<>
			<section>
				<div>
					<Sidebar />
				</div>
				<section className="main">
					<aside className="p-3">
						<div style={{ paddingTop: "40px" }}>
							<Outlet />
						</div>
					</aside>
					<div className="right-div p-3">
						{pathname.includes("/game-room-code") && room_code ? (
							<PlayGame />
						) : (
							""
						)}
					</div>
				</section>
			</section>
			{/* <ToastContainer /> */}
		</>
	);
};
export default MainArea;
