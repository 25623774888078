import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { selectUser } from "../features/auth/authSlice";
import {
	addScreenshots,
	gameRequest,
	roomCodeAllData,
} from "../Services/usersApi";
import { AiOutlineCopy } from "react-icons/ai";

const PlayGame = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	const [createRoomCode, setCreateRoomCode] = useState("");
	const notify = (message, toastId) => {
		toast(message, {
			toastId: toastId,
		});
	};
	const { user } = useSelector(selectUser);
	const [isUploading, setIsUploading] = useState(false);
	const [aData, setAData] = useState({
		image: null,
		status: "",
		reason: "",
	});

	const handleImage = async (e) => {
		e.preventDefault();
		setIsUploading(true);
		const sData = new FormData();
		sData.set("user_id", user.id);
		sData.set("room_code", createRoomCode || "");
		sData.set("game_status", aData.status);
		if (aData.status === "cancel") {
			sData.set("reason", aData.reason);
		} else if (aData.status === "won") {
			sData.set("screen_shot_img", aData.image ? aData.image : null);
		}
		if (aData.status === "won" && !aData.image) {
			notify("please choose image");
		} else {
			const res = await addScreenshots(sData);

			if (res.status) {
				notify(res.message);
				navigate(`/game-room-code/${state?.game_id}`);
				localStorage.removeItem("activeGame");
			} else {
				notify(res.message);
			}
		}
		setIsUploading(false);
	};

	const fetchRoomCode = async () => {
		if (state.id) {
			const res = await roomCodeAllData(state.id);
			if (res.message === "Reject") {
				notify("Opponent rejected the request", "reject");
				localStorage.removeItem("activeGame");
				navigate(`/game-room-code/${state?.game_id}`);
				return false;
			} else if (
				+res.data.user1status === +user.id ||
				+res.data.user2status === +user.id
			) {
				notify("You have already updated the status.", "update_state");
				localStorage.removeItem("activeGame");
				setTimeout(() => {
					navigate(`/game-room-code/${state?.game_id}`);
				}, 1000);
				return false;
			} else {
				if (res.data.room_code) {
					setCreateRoomCode(res.data.room_code);
					const localRoom = {
						game_id: state?.game_id,
						roomCode: res.data.room_code,
						id: state.id,
						user_id: user.id,
					};

					localStorage.setItem("activeGame", JSON.stringify(localRoom));
					if (res.data.status === "completed") {
						localStorage.removeItem("activeGame");
						notify("opponent lost", "lost");
						setTimeout(() => {
							navigate(`/game-room-code/${state?.game_id}`);
						}, 1000);
					}
				} else {
					setCreateRoomCode("");
				}
			}
		}
	};

	const getRejectGameRequest = async () => {
		const rData = {
			id: state.id,
			type: "reject",
		};
		const res = await gameRequest(rData);
		if (res.status) {
			notify("Request cancelled");
			navigate(`/game-room-code/${state?.game_id}`);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			fetchRoomCode();
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	});

	return (
		<>
			<div className="right-div-design p-2">
				{createRoomCode ? (
					<>
						<h3 className="fw-bold text-center custom-text">Play Game</h3>
						<div>
							<form className="mt-4" onSubmit={handleImage}>
								<div className="m-auto w-50 mt-4 ">
									<p className="text-center m-1 text-dark">
										Here is <span className="fw-bold">Room Code :</span>
									</p>
									<div className="d-flex align-items-center ">
										<Form.Group>
											<Form.Control
												type="text"
												readOnly
												value={createRoomCode}
												className="py-3 text-center text-black fw-bold"
												style={{ fontSize: "23px" }}
											/>
										</Form.Group>

										<Button
											className="custom-color border-0 shadow-none  ms-2"
											style={{
												borderRadius: "1",
												padding: "10px 10px",
											}}
											onClick={() => {
												if (navigator.clipboard && window.isSecureContext) {
													return navigator.clipboard
														.writeText(createRoomCode)
														.then(() => {
															notify("Room Code Copied Successfuly!");
														});
												} else {
													let textArea = document.createElement("textarea");
													textArea.value = createRoomCode;
													textArea.style.position = "fixed";
													textArea.style.left = "-999999px";
													textArea.style.top = "-999999px";
													document.body.appendChild(textArea);
													textArea.focus();
													textArea.select();
													return new Promise((res, rej) => {
														document.execCommand("copy") ? res() : rej();
														textArea.remove();
														notify("Room Code Copied Successfuly!");
													});
												}
											}}>
											<AiOutlineCopy className="fs-4" />
										</Button>
									</div>
								</div>

								<Row>
									<Col>
										<Form.Group>
											<Form.Label className="text-dark">Status</Form.Label>
											<Form.Control
												className="shadow-none"
												as="select"
												value={aData.status}
												onChange={(e) => {
													setAData({ ...aData, status: e.target.value });
												}}>
												<option value="">Select.....</option>
												<option value="won">Won</option>
												<option value="lost">Lost</option>
												<option value="cancel">Cancel</option>
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>
								{aData.status === "won" ? (
									<Row className="mt-3">
										<Col>
											<Form.Group className="mb-3">
												<Form.Label className="text-dark">
													Upload Screenshots
												</Form.Label>
												<Form.Control
													className="shadow-none"
													type="file"
													accept="image/*"
													onChange={(e) => {
														if (e) {
															const allowed = ["jpg", "jpeg", "png", "webp"];
															const ext = e.target.files[0].name.split(".")[1];
															if (allowed.includes(ext)) {
																setAData({
																	...aData,
																	image: e.target.files[0],
																});
															} else {
																notify("file type not allowed");
															}
														}
													}}
												/>
											</Form.Group>
										</Col>
									</Row>
								) : aData.status === "cancel" ? (
									<Row className="mx-auto">
										<Col md={"12"} className="p-0">
											<Form.Group className="mb-2 mt-3">
												<Form.Label className="text-black">
													Why you want to leave?
												</Form.Label>
												<Form.Control
													name="reason"
													value={aData.reason}
													onChange={(e) => {
														setAData({ ...aData, reason: e.target.value });
													}}
													required
													as="textarea"
													className="shadow-none "
													placeholder="type...."
												/>
											</Form.Group>
										</Col>
									</Row>
								) : (
									""
								)}
								<Button
									className="mt-5 me-2 w-100"
									variant="success"
									type="submit"
									disabled={isUploading}>
									{isUploading ? "Please wait…" : "Submit"}
								</Button>
							</form>
						</div>
					</>
				) : (
					<>
						<h5
							className="text-dark d-flex justify-content-center"
							style={{ marginTop: "150px" }}>
							Waiting... Waiting... Waiting...
						</h5>
						<div className="text-center">
							<Button
								style={{ marginBottom: "120px" }}
								onClick={() => getRejectGameRequest()}
								variant="danger">
								Cancel
							</Button>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default PlayGame;
