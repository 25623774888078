import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  room_code: "",
};

const gameSlice = createSlice({
  name: "roomCode",
  initialState,
  reducers: {
    setCode: (state, action) => {
      state.room_code = action.payload;
    },
  },
});

export const { setCode } = gameSlice.actions;
export const selectCode = (state) => state.roomCode;
export default gameSlice.reducer;
