import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: 0,
};

const walletSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
  },
});

export const { setBalance } = walletSlice.actions;
export const selectWallet = (state) => state.balance;
export default walletSlice.reducer;
