import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../features/auth/authSlice";
import MainArea from "../layouts/MainArea";

export function RequireAuth() {
	const { isAuthenticated } = useSelector(selectUser);
	let location = useLocation();
	if (!isAuthenticated) {
		return <Navigate to="/" state={{ from: location }} replace />;
	}
	return <MainArea />;
}
export function PublicRoutes() {
	const { isAuthenticated } = useSelector(selectUser);
	let location = useLocation();
	// const to = location.state?.from?.pathname || "/home";
	if (!isAuthenticated) {
		return <Outlet />;
	}
	return <Navigate to={"/home"} state={{ from: location }} replace />;
}
