import { lazy, Suspense, useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { PublicRoutes, RequireAuth } from "./components/AuthChecker";
import { useLayoutEffect } from "react";
import { fetchMyProfile, getAllSettings } from "./Services/usersApi";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser, setUser } from "./features/auth/authSlice";
import { setSettings } from "./features/settings/settingsSlice";
import { Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Conditions = lazy(() => import("./pages/Conditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Profile = lazy(() => import("./pages/Profile"));
const MyWallet = lazy(() => import("./pages/MyWallet"));
const AddFunds = lazy(() => import("./pages/AddFunds"));
const GameHistory = lazy(() => import("./pages/GameHistory"));
const TransactionHistory = lazy(() => import("./pages/TransactionHistory"));
const Notifications = lazy(() => import("./pages/Notifications"));
const ReferHistory = lazy(() => import("./pages/ReferHistory"));
const ReferEarn = lazy(() => import("./pages/ReferEarn"));
const Redeem = lazy(() => import("./pages/Redeem"));
const Support = lazy(() => import("./pages/Support"));
const UpdatePan = lazy(() => import("./pages/UpdatePan"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const HomePage = lazy(() => import("./components/HomePage"));
const WithdrawUPI = lazy(() => import("./components/WithdrawUPI"));
const CompleteKYC = lazy(() => import("./pages/CompleteKYC"));
const PaymentSuccessfull = lazy(() => import("./pages/PaymentSuccessfull"));
const GameButtons = lazy(() => import("./pages/GameButtons"));
const GameRoomCode = lazy(() => import("./pages/GameRoomCode"));
const WithdrwalIns = lazy(() => import("./pages/WithdrwalIns"));
const RoomCodeIns = lazy(() => import("./pages/RoomCodeIns"));
const DepositIns = lazy(() => import("./pages/DepositIns"));
const ChattingPage = lazy(() => import("./pages/ChattingPage"));
const PlayGame = lazy(() => import("./pages/PlayGame"));
const RoomCodeDetail = lazy(() => import("./pages/RoomCodeDetail"));
const AgentForm = lazy(() => import("./pages/AgentForm"));

const App = () => {
	const { user } = useSelector(selectUser);
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const fetchData = async () => {
		const res = await fetchMyProfile(user?.id);

		if (res.status) {
			const loginId = localStorage.getItem("loginId");
			if (res.data.loginid === loginId) {
				dispatch(setUser(res.data));
			} else {
				alert("You have already loggedin in another device");
				dispatch(logout());
			}
			// dispatch(setUser(res.data));
		} else {
			// alert("please login...");
			localStorage.removeItem("loginId");
			dispatch(logout());
		}
	};
	const fetchSettings = useCallback(async () => {
		const res = await getAllSettings();

		if (res.status) {
			dispatch(setSettings(res.data));
		}
	}, [dispatch]);
	useLayoutEffect(() => {
		// console.log(pathname);
		fetchSettings();
		fetchData();
		// const interval = setInterval(() => {
		// }, 1000);

		// return () => {
		// 	clearInterval(interval);
		// };
	}, [pathname]);
	return (
		<div className="App">
			<Suspense
				fallback={
					<div
						style={{
							width: "100vw",
							height: "100vh",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				}>
				<Routes>
					<Route>
						<Route element={<PublicRoutes />}>
							<Route path="/" element={<Login />} />
							<Route path="/sign-up" element={<SignUp />} />
						</Route>
						<Route path="/agent-signup" element={<AgentForm />} />
						<Route element={<RequireAuth />}>
							<Route path="/home" element={<HomePage />} />
							<Route path="/conditions" element={<Conditions />} />
							<Route path="/privacy-policy" element={<PrivacyPolicy />} />
							<Route path="/deposit-ins" element={<DepositIns />} />
							<Route path="/withdrawal-ins" element={<WithdrwalIns />} />
							<Route path="/contact-us" element={<ContactUs />} />
							<Route path="/roomcode-ins" element={<RoomCodeIns />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/my-wallet" element={<MyWallet />} />
							<Route path="/add-funds" element={<AddFunds />} />
							<Route path="/withdraw-funds" element={<WithdrawUPI />} />
							<Route path="/games-history" element={<GameHistory />} />
							<Route
								path="/transaction-history"
								element={<TransactionHistory />}
							/>
							<Route path="/refer-history" element={<ReferHistory />} />
							<Route path="/ChatBox" element={<ChattingPage />} />
							<Route path="/notifications" element={<Notifications />} />
							<Route path="/game-buttons/:id" element={<GameButtons />} />
							<Route path="/refer-earn" element={<ReferEarn />} />
							<Route path="/redeem" element={<Redeem />} />
							<Route path="/support" element={<Support />} />
							<Route path="/update-pancard" element={<UpdatePan />} />
							<Route path="/complete-kyc" element={<CompleteKYC />} />
							<Route path="/game-room-code/:id" element={<GameRoomCode />} />
							<Route path="/play-game" element={<PlayGame />} />
							<Route
								path="/room-code-detail/:rcode"
								element={<RoomCodeDetail />}
							/>
							<Route path="/payment-status" element={<PaymentSuccessfull />} />
						</Route>
					</Route>
				</Routes>
			</Suspense>
			<ToastContainer />
		</div>
	);
};

export default App;
