import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
	persistStore,
	persistReducer,
	REGISTER,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./auth/authSlice";
import walletReducer from "./wallet/walletSlice";
import kycReducer from "./status/KycSlice";
import gameReducer from "./game/gameSlice";
import webSettingsReducer from "./settings/settingsSlice";

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	whiteList: ["auth", "balance", "kycStatus", "webSettings"],
};

const rootReducer = combineReducers({
	auth: authReducer,
	balance: walletReducer,
	kycStatus: kycReducer,
	roomCode: gameReducer,
	webSettings: webSettingsReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);
